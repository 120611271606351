
import { defineComponent, onBeforeMount, reactive, ref, toRefs, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import moment from "moment";
import { useFieldValue } from "vee-validate";

export default defineComponent({
  name: "edit-invoice-modal",
  components: {
  },
  props:{
    invoiceId : Number,
    students: [] as any
  },
  emits: ['getInvoices', 'updateInvoiceId'],
  setup(props, {emit}) {
    const store = useStore();
    const { students, invoiceId } = toRefs(props);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const user = store.getters.currentUser;
    const selectedStudent = ref('');
    const invoiceData = ref({} as any);
    const financeRecordList = reactive({records : [] as any});

    const resetForm = () =>{
      emit("updateInvoiceId", 0);
      invoiceData.value = {};
    }

    const getAvailableFinancerecords = async (student_id : number) => {
        await new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("finance/records/invoice/"+student_id)
                .then(({ data }) => {
                    financeRecordList.records = data.data;
                    sortByRecordDate();
                    resolve(data.data);
                })
                .catch(({ response }) => {
                  console.log(response);
                    reject(new Error ('getAvailableFinancerecords error => '+response.data.errors));
                });
        });
    }

    const selectStudent = async(item) => {
      if(item){
        invoiceData.value.student_id = item.id;
        selectedStudent.value = item.first_name +" "+ item.last_name +"("+item.student_id+")";
        await getAvailableFinancerecords(item.id).then(()=>{
          invoiceData.value.finance_records = [];
        });
      }
    }

    const setEmptyInvoiceData = () =>{
        invoiceData.value = {
            invoice_date  :'',
            student_id : Number(),
            invoice_records : [] as any,
            finance_records : [] as any,
            memo  :'',
            user_id: user.id,
            student: {} as any
        };

        if(props.students.length == 1){
          let studentObj = Object.assign({},props.students[0]);
          selectStudent(studentObj);
        }
        else{
          selectedStudent.value = '';
          financeRecordList.records = [];
        }
    }

    watch(() => props.invoiceId, async(cur, prev)=>{
        await setDefaultInvoiceStudent(props.invoiceId);
    })

    watch(() => props.students, async(cur, prev)=>{
        await setDefaultInvoiceStudent(props.invoiceId);
    })

    const setDefaultInvoiceStudent = async(id) =>{
        if(id && id > 0 ){
            getInvoice(id).then(()=>{
                const financeRecords = [...invoiceData.value.invoice_records.map(x=>x.finance_record)];
                selectStudent(invoiceData.value.student).then(() =>{
                  invoiceData.value.finance_records = [...financeRecords];
                  financeRecordList.records = financeRecordList.records.filter(x=> invoiceData.value.finance_records.filter(y=>y.id == x.id).length == 0);
                });
            });
        }
        else{
            setEmptyInvoiceData();
        }
    }
    
    const getInvoice = async (id : number) => {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("invoice/"+id)
                .then(({ data }) => {
                    Object.assign(invoiceData.value,data.data);
                    resolve('done');
                })
                .catch(({ response }) => {
                    reject(new Error ('getInvoice error => '+response.data.errors));
                });
        });
    }

    if(invoiceId.value !== undefined && invoiceId.value !== 0){
        getInvoice(invoiceId.value).then(async() =>{
            await getAvailableFinancerecords(invoiceData.value.student_id);
        });
    }
    else{
        setEmptyInvoiceData();
    }

    const getStudent=(queryString, callback)=>{
        let selectedList = students.value.filter(data => (!queryString 
                            || (data.first_name +" "+ data.last_name +"("+data.student_id+")").toLowerCase().includes(queryString.toLowerCase())));
        callback(selectedList);
    }

    const addToInvoice = (row) => {
        invoiceData.value.finance_records.push(row);
        sortByRecordDate();
        let index = financeRecordList.records.findIndex(d => d.id === row.id);
        financeRecordList.records.splice(index,1);  
    }

    const removeFromInvoice = (row) => {
        financeRecordList.records.push(row);
        sortByRecordDate();
        let index = invoiceData.value.finance_records.findIndex(d => d.id === row.id);
        invoiceData.value.finance_records.splice(index,1);
    }

    const sortByRecordDate = () =>{
        financeRecordList.records.sort(function(a,b) { 
                    if(a.id == b.id) return 0;

                    if (Date.parse(a.record_date!) < Date.parse(b.record_date!))
                        return 1;
                    if (Date.parse(a.record_date!) > Date.parse(b.record_date!))
                        return -1;
                    return 0;
                });
    }

    const rules = ref({
      student_id: [
        {
          required: true,
          message: "Please select student",
          trigger: "blur",
        },
      ],
      finance_records: [
        {
          required: true,
          message: "Please select finance records",
          trigger: "change",
        },
      ]
    });

    const editInvoice = async (formData) => {
        delete formData.finance_records;
        ApiService.setHeader();
        if(invoiceId.value !== undefined && invoiceId.value !==0)
        {
            ApiService.update("invoice", invoiceId.value, formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('editInvoice error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to edit invoice",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
        else
        {
            ApiService.post("invoice", formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('createInvoice error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new invoice",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      invoiceData.value.invoice_records = [...invoiceData.value.finance_records.map(x=>{return {invoice_id: invoiceId.value, record_id: x.id};})]
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          editInvoice(invoiceData.value).then(() => {
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              loading.value = false;
              emit('getInvoices');
              let closeEditBtn = document.getElementById('close_invoice_button');
              closeEditBtn?.click();
            });
          });
        } else {
          Swal.fire({
            text: "Please fill out all required fields",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      invoiceData,
      submit,
      rules,
      loading,
      formRef,
      getStudent,
      selectStudent,
      selectedStudent,
      financeRecordList,
      moment,
      addToInvoice,
      removeFromInvoice,
      resetForm
    };
  },
});
