
import { defineComponent, onBeforeMount, reactive, ref } from "vue";
import { ProfileDetails } from "@/types/StudentModel";
import { Address } from "@/types/AddressModel";
import { setStudentProfile } from "@/core/helpers/student";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import CreateInvoice from   "@/views/management/modals/EditInvoice.vue"
import _ from "lodash";
import moment from "moment";

export default defineComponent({
    name: "student-detail",
    components: {
      CreateInvoice
    },
    setup(){
        const route = useRoute();
        const id : number = +route.params.id; 
        const profileDetails : any = reactive({});
        const session = ref('N/A');
        const attendanceRate = ref<any>(null);
        const totalReceivable = ref(0);
        
        const getStudentProfile = async () => {
            await new Promise((resolve, reject) => {   
                ApiService.setHeader();         
                ApiService.get("student/profile/"+id)
                    .then(async({ data }) => {
                        if(data.data.address == null){
                          data.data.address = new Address();
                        }
                        Object.assign(profileDetails,data.data);
                        await getProfilePhotoUrl();
                        setStudentProfile(profileDetails);
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        console.log('getFinanceRecords error => '+response.data.errors);
                        reject(new Error ('getFinanceRecords error => '+response.data.errors));
                    });
            });
        }

        const getStudentTotalReceivable = async () =>{
            await new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("finance/records/receivable/"+id)
                    .then(({ data }) => {
                        if(data.data){
                          totalReceivable.value = data.data;
                        }
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        console.log('getFinanceRecords error => '+response.data.errors);
                        reject(new Error ('getFinanceRecords error => '+response.data.errors));
                    });
            });
        }

        const getSubjects = async () => {
            await new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("class-student/subjects/"+id)
                    .then(({ data }) => {
                        var today = moment();
                        if(data.data){                          
                          let currentData = data.data.filter(item => moment(item.subject.session.level.program.semester.start_date) <= today && moment(item.subject.session.level.program.semester.end_date) >= today);
                          
                          session.value = currentData ? currentData[0].subject.session.code : "N/A";
                          let sumOfAttendanceRate = currentData.map(item => item.attendance_rate).reduce((prev, curr) => prev + curr, 0);
                          attendanceRate.value = Math.floor(sumOfAttendanceRate / currentData.length);
                        }
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        console.log('getSubjects error => '+response.data.errors);
                        reject(new Error ('getSubjects error => '+response.data.errors));
                    });
            });
        }

        const getProfilePhotoUrl = async() =>{
            if(profileDetails && profileDetails.photo_id){
                await ApiService.get("file/url/"+profileDetails.photo_id)
                        .then(({ data }) => {
                          profileDetails.photoUrl = data.url;
                        });
            }
        }

        const setStudentProfileDetail = () => {
            if(id != 0){
                getStudentProfile();
                getSubjects();
                getStudentTotalReceivable();
            }
            else{
                Object.assign(profileDetails,new ProfileDetails());
                setStudentProfile(profileDetails);
            }
        }

        onBeforeMount(() => {
            setStudentProfileDetail();
        });

        return {
            profileDetails,
            id,
            session,
            attendanceRate,
            totalReceivable
        };
    }
});
