<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-if="id != 0">
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img v-if="profileDetails.photoUrl != undefined && profileDetails.photoUrl != ''" v-bind:src="profileDetails.photoUrl" alt="image" />
            <img v-else src="/media/avatars/blank.png" alt="image" />
            <div
              class="
                position-absolute
                translate-middle
                bottom-0
                start-100
                mb-6
                bg-success
                rounded-circle
                border border-4 border-white
                h-20px
                w-20px
              "
            ></div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <span
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >{{profileDetails.first_name}} {{profileDetails.last_name}}
                </span>
              </div>
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <span
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com006.svg"
                    />
                  </span>
                  {{profileDetails.status}}
                </span>
                <span
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="/media/icons/duotune/general/gen018.svg" />
                  </span>
                  {{profileDetails.admission_category}}
                </span>
                <span
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    mb-2
                    me-5
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com011.svg"
                    />
                  </span>
                  {{profileDetails.email}}
                </span>
                <span
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1" style="opacity: 0.3;">
                    <inline-svg
                      src="/media/icons/duotune/communication/phone-solid.svg"
                    />
                  </span>
                  {{profileDetails.phone}}
                </span>
              </div>
            </div>
            <div class="d-flex my-4">
              <a
                href="#"
                class="btn btn-sm btn-primary me-3"
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_invoice"
                >Create Invoice</a
              >
            </div>
          </div>
          <div class="d-flex flex-wrap flex-stack">
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <div class="d-flex flex-wrap">
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-success me-2">
                      <inline-svg src="/media/icons/duotune/arrows/arr066.svg" />
                    </span>
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="4500"
                      data-kt-countup-prefix="$"
                    >
                      ${{totalReceivable}}
                    </div>
                  </div>
                  <div class="fw-bold fs-6 text-gray-400">Receivable</div>
                </div>
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                      <inline-svg src="/media/icons/duotune/general/gen026.svg" />
                    </span>
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="75"
                      title="lastest session"
                    >
                      {{session}}
                    </div>
                  </div>
                  <div class="fw-bold fs-6 text-gray-400">Session</div>
                </div>
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                      <inline-svg src="/media/icons/duotune/arrows/arr066.svg" />
                    </span>
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="60"
                      data-kt-countup-prefix="%"
                    >
                      <span v-if="attendanceRate">{{attendanceRate}} %</span>
                      <span v-else>N/A</span>
                    </div>
                  </div>
                  <div class="fw-bold fs-6 text-gray-400" title="current session attendance">Attendance Rate</div>
                </div>
                <div
                  v-if="profileDetails.agent"
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <div class="d-flex align-items-center">
                    <div
                      class="fs-2 fw-bolder"
                      data-kt-countup="true"
                      data-kt-countup-value="60"
                      data-kt-countup-prefix="%"
                    >
                      {{profileDetails.agent.name}}
                    </div>
                  </div>
                  <div class="fw-bold fs-6 text-gray-400">Agent Name</div>
                </div>
              </div>
            </div>

            <div
              class="
                d-flex
                align-items-center
                w-200px w-sm-300px
                flex-column
                mt-3
              "
            >
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>New student</h3>
      </div>

      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          ">
          <li class="nav-item">
            <router-link
              :to="{path : `/student/${id}/profile`}"
              class="nav-link text-active-primary me-6"
              active-class="active">
              Profile
            </router-link>
          </li>
          <li class="nav-item" v-if="id != 0">
            <router-link
              :to="{path : `/student/${id}/academic`}"
              class="nav-link text-active-primary me-6"
              active-class="active">
              Academics
            </router-link>
          </li>
          <li class="nav-item"  v-if="id != 0">
            <router-link
              :to="{path : `/student/${id}/ledger`}"
              class="nav-link text-active-primary me-6"
              active-class="active">
              Ledger
            </router-link>
          </li>
          <li class="nav-item" v-if="id != 0">
            <router-link
              :to="{path : `/student/${id}/files`}"
              class="nav-link text-active-primary me-6"
              active-class="active">
              Files
            </router-link>
          </li>
          <li class="nav-item" v-if="id != 0">
            <router-link
              :to="{path : `/student/${id}/memo`}"
              class="nav-link text-active-primary me-6"
              active-class="active">
              Memo
            </router-link>
          </li>
          <li class="nav-item" v-if="id != 0">
            <router-link
              :to="{path : `/student/${id}/reports`}"
              class="nav-link text-active-primary me-6"
              active-class="active">
              Report
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="id != 0">
    <CreateInvoice
        v-bind:invoiceId="0"
        v-bind:students="[profileDetails]">
    </CreateInvoice>
  </template>
  </div>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref } from "vue";
import { ProfileDetails } from "@/types/StudentModel";
import { Address } from "@/types/AddressModel";
import { setStudentProfile } from "@/core/helpers/student";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import CreateInvoice from   "@/views/management/modals/EditInvoice.vue"
import _ from "lodash";
import moment from "moment";

export default defineComponent({
    name: "student-detail",
    components: {
      CreateInvoice
    },
    setup(){
        const route = useRoute();
        const id : number = +route.params.id; 
        const profileDetails : any = reactive({});
        const session = ref('N/A');
        const attendanceRate = ref<any>(null);
        const totalReceivable = ref(0);
        
        const getStudentProfile = async () => {
            await new Promise((resolve, reject) => {   
                ApiService.setHeader();         
                ApiService.get("student/profile/"+id)
                    .then(async({ data }) => {
                        if(data.data.address == null){
                          data.data.address = new Address();
                        }
                        Object.assign(profileDetails,data.data);
                        await getProfilePhotoUrl();
                        setStudentProfile(profileDetails);
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        console.log('getFinanceRecords error => '+response.data.errors);
                        reject(new Error ('getFinanceRecords error => '+response.data.errors));
                    });
            });
        }

        const getStudentTotalReceivable = async () =>{
            await new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("finance/records/receivable/"+id)
                    .then(({ data }) => {
                        if(data.data){
                          totalReceivable.value = data.data;
                        }
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        console.log('getFinanceRecords error => '+response.data.errors);
                        reject(new Error ('getFinanceRecords error => '+response.data.errors));
                    });
            });
        }

        const getSubjects = async () => {
            await new Promise((resolve, reject) => {  
                ApiService.setHeader();          
                ApiService.get("class-student/subjects/"+id)
                    .then(({ data }) => {
                        var today = moment();
                        if(data.data){                          
                          let currentData = data.data.filter(item => moment(item.subject.session.level.program.semester.start_date) <= today && moment(item.subject.session.level.program.semester.end_date) >= today);
                          
                          session.value = currentData ? currentData[0].subject.session.code : "N/A";
                          let sumOfAttendanceRate = currentData.map(item => item.attendance_rate).reduce((prev, curr) => prev + curr, 0);
                          attendanceRate.value = Math.floor(sumOfAttendanceRate / currentData.length);
                        }
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        console.log('getSubjects error => '+response.data.errors);
                        reject(new Error ('getSubjects error => '+response.data.errors));
                    });
            });
        }

        const getProfilePhotoUrl = async() =>{
            if(profileDetails && profileDetails.photo_id){
                await ApiService.get("file/url/"+profileDetails.photo_id)
                        .then(({ data }) => {
                          profileDetails.photoUrl = data.url;
                        });
            }
        }

        const setStudentProfileDetail = () => {
            if(id != 0){
                getStudentProfile();
                getSubjects();
                getStudentTotalReceivable();
            }
            else{
                Object.assign(profileDetails,new ProfileDetails());
                setStudentProfile(profileDetails);
            }
        }

        onBeforeMount(() => {
            setStudentProfileDetail();
        });

        return {
            profileDetails,
            id,
            session,
            attendanceRate,
            totalReceivable
        };
    }
});
</script>
