<template>
  <div
    class="modal fade"
    id="modal_edit_invoice"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content">
        <div class="modal-header">
            <h2>
                <span v-if="invoiceId===0" >Create</span>
                <span v-else>Edit</span> invoice
            </h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            @click="resetForm()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <el-form
            id="edit_invoice_form"
            :model="invoiceData"
            :rules="rules"
            ref="formRef"
            class="form"
          >
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span class="required">Date</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="invoice_date">
                  <el-date-picker
                    v-model="invoiceData.invoice_date"
                    type="date"
                    start-placeholder="invoice date"
                    format="MM/DD/YYYY"
                    >
                  </el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Student</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="student">
                  <el-autocomplete
                    class="form-select-solid"
                    v-model="selectedStudent"
                    :fetch-suggestions="getStudent"
                    :clearable="true"
                    placeholder="Enter student name or id"
                    @select="selectStudent">
                    <template v-slot="scope">
                      {{ scope.item.first_name +" "+ scope.item.last_name +"("+scope.item.student_id+")"}}
                  </template>
                  </el-autocomplete>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Finance transactions</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="finance_records" v-if="invoiceData.student_id">
                    <div v-if="invoiceData.finance_records.length >0">
                        <span style="font-size: 15px; color: blue; font-weight: 700;">Selected transactions</span>
                        <div style="max-height:250px;width:100%;background-color: #dce7eb;padding:8px;padding-left:15px;padding-right:15px;margin-top: -5px;overflow:scroll;">
                            <div v-for="record in invoiceData.finance_records" :key="record.id" :value="record.id" style="display: flex;width: 100%;line-height: 32px;">
                                {{moment(record.record_date).format('MM/DD/YYYY')}} {{record.record_type}} {{record.amount.toLocaleString("en-US", {style: "currency", currency: "USD"})}} {{record.payment_method}} {{record.payment_type}} {{record.payment_memo}}
                            <img src="/media/icons/duotune/general/gen042.svg" class="svg-icon svg-icon-2x svg-icon-primary cursor-pointer" title="Remove"
                                    style="width:20px;"
                                    @click="removeFromInvoice(record)"/>
                            </div>
                        </div>
                    </div>
                    <el-table
                        :data="financeRecordList.records"
                        :max-height="250"
                        style="width: 100%;margin-top:10px;">
                        <el-table-column width="110"
                            prop="date"
                            label="Date">
                            <template v-slot="scope">
                                {{moment(scope.row.record_date).format('MM/DD/YYYY')}}
                            </template>
                        </el-table-column>
                        <el-table-column width="110"
                            prop="record_type"
                            label="Type">
                        </el-table-column>
                        <el-table-column
                            prop="amount"
                            label="Amount">
                        </el-table-column>
                        <el-table-column
                            prop="payment_method"
                            label="Method">
                        </el-table-column>
                        <el-table-column
                            prop="payment_type"
                            label="Category">
                        </el-table-column>
                        <el-table-column
                            prop="memo"
                            label="Memo">
                        </el-table-column>
                        <el-table-column width="50">
                            <template v-slot="scope">
                                <img src="/media/icons/duotune/general/gen041.svg" class="svg-icon svg-icon-2x svg-icon-primary cursor-pointer" title="Add"
                                    style="width:20px;"
                                    @click="addToInvoice(scope.row)"/>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <span v-else>Select student</span>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Memo</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="memo">
                <input
                    v-model="invoiceData.memo"
                    style="width:100%;"
                    class="form-control form-control-lg form-control-solid"
                />
                </el-form-item>
              </div>
            </div>
            <div class="text-center">
              <button
                id="close_invoice_button"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <el-button type="primary" @click="submit()" :loading="loading">
                {{ loading ? "Please wait..." : "Submit" }}
              </el-button>
            </div>
            </el-form>
          </div>
        </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create App-->
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref, toRefs, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import moment from "moment";
import { useFieldValue } from "vee-validate";

export default defineComponent({
  name: "edit-invoice-modal",
  components: {
  },
  props:{
    invoiceId : Number,
    students: [] as any
  },
  emits: ['getInvoices', 'updateInvoiceId'],
  setup(props, {emit}) {
    const store = useStore();
    const { students, invoiceId } = toRefs(props);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const user = store.getters.currentUser;
    const selectedStudent = ref('');
    const invoiceData = ref({} as any);
    const financeRecordList = reactive({records : [] as any});

    const resetForm = () =>{
      emit("updateInvoiceId", 0);
      invoiceData.value = {};
    }

    const getAvailableFinancerecords = async (student_id : number) => {
        await new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("finance/records/invoice/"+student_id)
                .then(({ data }) => {
                    financeRecordList.records = data.data;
                    sortByRecordDate();
                    resolve(data.data);
                })
                .catch(({ response }) => {
                  console.log(response);
                    reject(new Error ('getAvailableFinancerecords error => '+response.data.errors));
                });
        });
    }

    const selectStudent = async(item) => {
      if(item){
        invoiceData.value.student_id = item.id;
        selectedStudent.value = item.first_name +" "+ item.last_name +"("+item.student_id+")";
        await getAvailableFinancerecords(item.id).then(()=>{
          invoiceData.value.finance_records = [];
        });
      }
    }

    const setEmptyInvoiceData = () =>{
        invoiceData.value = {
            invoice_date  :'',
            student_id : Number(),
            invoice_records : [] as any,
            finance_records : [] as any,
            memo  :'',
            user_id: user.id,
            student: {} as any
        };

        if(props.students.length == 1){
          let studentObj = Object.assign({},props.students[0]);
          selectStudent(studentObj);
        }
        else{
          selectedStudent.value = '';
          financeRecordList.records = [];
        }
    }

    watch(() => props.invoiceId, async(cur, prev)=>{
        await setDefaultInvoiceStudent(props.invoiceId);
    })

    watch(() => props.students, async(cur, prev)=>{
        await setDefaultInvoiceStudent(props.invoiceId);
    })

    const setDefaultInvoiceStudent = async(id) =>{
        if(id && id > 0 ){
            getInvoice(id).then(()=>{
                const financeRecords = [...invoiceData.value.invoice_records.map(x=>x.finance_record)];
                selectStudent(invoiceData.value.student).then(() =>{
                  invoiceData.value.finance_records = [...financeRecords];
                  financeRecordList.records = financeRecordList.records.filter(x=> invoiceData.value.finance_records.filter(y=>y.id == x.id).length == 0);
                });
            });
        }
        else{
            setEmptyInvoiceData();
        }
    }
    
    const getInvoice = async (id : number) => {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("invoice/"+id)
                .then(({ data }) => {
                    Object.assign(invoiceData.value,data.data);
                    resolve('done');
                })
                .catch(({ response }) => {
                    reject(new Error ('getInvoice error => '+response.data.errors));
                });
        });
    }

    if(invoiceId.value !== undefined && invoiceId.value !== 0){
        getInvoice(invoiceId.value).then(async() =>{
            await getAvailableFinancerecords(invoiceData.value.student_id);
        });
    }
    else{
        setEmptyInvoiceData();
    }

    const getStudent=(queryString, callback)=>{
        let selectedList = students.value.filter(data => (!queryString 
                            || (data.first_name +" "+ data.last_name +"("+data.student_id+")").toLowerCase().includes(queryString.toLowerCase())));
        callback(selectedList);
    }

    const addToInvoice = (row) => {
        invoiceData.value.finance_records.push(row);
        sortByRecordDate();
        let index = financeRecordList.records.findIndex(d => d.id === row.id);
        financeRecordList.records.splice(index,1);  
    }

    const removeFromInvoice = (row) => {
        financeRecordList.records.push(row);
        sortByRecordDate();
        let index = invoiceData.value.finance_records.findIndex(d => d.id === row.id);
        invoiceData.value.finance_records.splice(index,1);
    }

    const sortByRecordDate = () =>{
        financeRecordList.records.sort(function(a,b) { 
                    if(a.id == b.id) return 0;

                    if (Date.parse(a.record_date!) < Date.parse(b.record_date!))
                        return 1;
                    if (Date.parse(a.record_date!) > Date.parse(b.record_date!))
                        return -1;
                    return 0;
                });
    }

    const rules = ref({
      student_id: [
        {
          required: true,
          message: "Please select student",
          trigger: "blur",
        },
      ],
      finance_records: [
        {
          required: true,
          message: "Please select finance records",
          trigger: "change",
        },
      ]
    });

    const editInvoice = async (formData) => {
        delete formData.finance_records;
        ApiService.setHeader();
        if(invoiceId.value !== undefined && invoiceId.value !==0)
        {
            ApiService.update("invoice", invoiceId.value, formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('editInvoice error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to edit invoice",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
        else
        {
            ApiService.post("invoice", formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('createInvoice error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new invoice",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      invoiceData.value.invoice_records = [...invoiceData.value.finance_records.map(x=>{return {invoice_id: invoiceId.value, record_id: x.id};})]
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          editInvoice(invoiceData.value).then(() => {
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              loading.value = false;
              emit('getInvoices');
              let closeEditBtn = document.getElementById('close_invoice_button');
              closeEditBtn?.click();
            });
          });
        } else {
          Swal.fire({
            text: "Please fill out all required fields",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      invoiceData,
      submit,
      rules,
      loading,
      formRef,
      getStudent,
      selectStudent,
      selectedStudent,
      financeRecordList,
      moment,
      addToInvoice,
      removeFromInvoice,
      resetForm
    };
  },
});
</script>

<style scoped>
* >>> .el-form-item {
  margin-bottom: 0px;
}

* >>> .el-table td {
    padding: 5px;
    font-size: 13px;
}

* >>> .el-table th {
    padding: 5px;
    font-size: 13px;
    line-height: 20px;
}
</style>