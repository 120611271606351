import { Address } from "./AddressModel";

export class ProfileDetails {
  constructor (){
    this.address = new Address();
    this.status = 'Active';
  }

  id: number;
  first_name: string;
  last_name: string;
  student_id : string;
  sevis_no : string;
  phone: string;
  email: string;
  address : Address;
  nationality: string;
  sex: string;
  birth_date: Date;
  i20_start: Date;
  i20_end: Date;
  start_date: Date;
  end_date: Date;
  agent_id : number;
  admission_category : string;
  status : string;
  picture : string;
  finance_records: [];
  session_code : string;
}

export enum BalanceFilterType {
  GreaterThan=">",
  GreaterThanOrEqualTo=">=",
  EqualTo="=",
  LessThanOrEqualTo="<=",
  LessThan="<",
}